import React from 'react';
import { motion } from 'framer-motion';
import { Code, Briefcase, ChevronDown } from 'lucide-react';
import ProjectCard from '../components/ProjectCard';
import SkillSlider from '../components/SkillSlider';
import AboutMe from '../components/AboutMe';
import GetInTouch from '../components/GetInTouch';

const HomePage = () => {
  const projects = [
    {
      id: 1,
      name: 'LPC Global Logistics',
      description: '',
      image: '/lpcgl.png',
      link: '#'
    },
    {
      id: 2,
      name: 'Midwives Kenya',
      description: '',
      image: '/mak.png',
      link: '#'
    },
    {
      id: 3,
      name: 'Meridian Health Group',
      description: '',
      image: '/mhg.png',
      link: '#'
    }
  ];

  return (
    <div className="min-h-screen bg-background dark:bg-accent text-text dark:text-background">
      <header className="flex h-[600px] sm:h-[550px] lg:h-[600px] flex-col justify-center items-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-primary to-accent opacity-90"></div>
        <div className="absolute inset-0 bg-background dark:bg-text opacity-10 backdrop-blur-lg"></div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="z-10 text-center flex flex-col items-center"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-48 h-48 rounded-full overflow-hidden border-4 border-background dark:border-background shadow-lg mb-8"
          >
            <img src="/geodhi.jpg" alt="George Odhiambo" className="w-full h-full object-cover" />
          </motion.div>
          <h1 className="text-xl md:text-3xl font-bold mb-4 text-background dark:text-background">
            George Odhiambo
          </h1>
          <p className="text-lg md:text-lg text-background dark:text-background">
            Software Engineer & Problem Solver
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
          className="absolute bottom-8 text-background dark:text-background"
        >
          <ChevronDown size={32} />
        </motion.div>
      </header>

      <div className="container mx-auto px-4 py-16">
        <AboutMe />

        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-24 mx-14"
        >
          <h2 className="text-xl font-semibold mb-12 flex items-center text-text dark:text-background">
            <Code className="mr-4 text-primary" /> Skills
          </h2>
          <SkillSlider />
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-24 mx-14"
        >
          <h2 className="text-xl font-semibold mb-12 flex items-center text-text dark:text-background">
            <Briefcase className="mr-4 text-primary" /> Featured Projects
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        </motion.section>

        <GetInTouch />
      </div>
    </div>
  );
};

export default HomePage;