import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Linkedin, Github, Twitter, Instagram, Send } from 'lucide-react';

const GetInTouch = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="bg-background dark:bg-accent rounded-3xl p-8 md:p-12 text-center"
    >
      <h2 className="text-xl font-semibold mb-6 flex items-center justify-center text-text dark:text-background">
        <Mail className="mr-4 text-primary" /> Get in Touch
      </h2>
      <p className="text-lg mb-8">info@geodhis.co.ke</p>
      
      <div className="flex justify-center space-x-6 mb-8">
        <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors duration-300">
          <Linkedin size={24} />
        </a>
        <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors duration-300">
          <Github size={24} />
        </a>
        <a href="https://twitter.com/yourusername" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors duration-300">
          <Twitter size={24} />
        </a>
        <a href="https://instagram.com/yourusername" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors duration-300">
          <Instagram size={24} />
        </a>
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Subscribe to my newsletter</h3>
        <div className="relative max-w-md mx-auto">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <button
            type="submit"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-primary hover:text-primary-hover transition-colors duration-300"
          >
            <Send size={20} />
          </button>
        </div>
      </div>
      
      <p className="text-sm text-gray-500 dark:text-gray-400">
        Powered by Geodhis
      </p>
    </motion.section>
  );
};

export default GetInTouch;