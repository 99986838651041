import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, Sun, Moon, LogIn, LogOut, User, Settings, FileText, BarChart } from 'lucide-react';
import { ThemeContext } from './ThemeContext';

const Navigation = ({ isAuthenticated, userRole, onSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDark, toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await onSignOut();
      navigate('/');
    } catch (error) {
      console.error('Sign out failed:', error);
    }
  };

  const navItems = [
    { path: '/', label: 'Home', icon: null, showAlways: true },
    { path: '/blog', label: 'Blog', icon: FileText, showAlways: true },
  ];

  if (isAuthenticated) {
    if (userRole === 'client') {
      navItems.push(
        { path: '/client/dashboard', label: 'Dashboard', icon: BarChart, showWhenAuth: true },
        { path: '/apply-for-service', label: 'Hire me', icon: Settings, showWhenAuth: true }
      );
    } else if (userRole === 'admin' || userRole === 'staff') {
      navItems.push(
        { path: '/admin/dashboard', label: 'Dashboard', icon: BarChart, showWhenAuth: true },
        { path: '/admin/users', label: 'Users', icon: User, showWhenAuth: true },
        { path: '/admin/settings', label: 'Settings', icon: Settings, showWhenAuth: true }
      );
    }
  }

  const authItems = isAuthenticated
    ? [{ path: '#', label: 'Sign Out', onClick: handleSignOut, icon: LogOut }]
    : [{ path: '/signin', label: 'Sign In', icon: LogIn }];

  const renderNavItem = (item) => (
    <Link
      key={item.path}
      to={item.path}
      onClick={() => {
        setIsOpen(false);
        item.onClick && item.onClick();
      }}
      className={`px-3 py-2 rounded-md text-sm font-medium flex items-center ${
        location.pathname === item.path
          ? 'bg-primary text-background'
          : 'text-text dark:text-background hover:bg-primary hover:text-background'
      }`}
    >
      {item.icon && <item.icon size={16} className="mr-2" />}
      {item.label}
    </Link>
  );

  const visibleNavItems = navItems.filter(item => 
    item.showAlways || (isAuthenticated && item.showWhenAuth)
  );

  return (
    <nav className="bg-background dark:bg-accent shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <span className="text-2xl font-bold text-primary">G<span className='text-text dark:text-background'>o <span className='text-primary'>_</span></span></span>
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              {visibleNavItems.map(renderNavItem)}
              {authItems.map(renderNavItem)}
              <button
                onClick={toggleTheme}
                className="p-2 rounded-full text-text dark:text-background hover:bg-primary hover:text-background focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-accent focus:ring-primary"
                aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
              >
                {isDark ? <Sun size={20} /> : <Moon size={20} />}
              </button>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-text dark:text-background hover:bg-primary hover:text-background focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-accent focus:ring-primary"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {visibleNavItems.map(renderNavItem)}
            {authItems.map(renderNavItem)}
            <button
              onClick={toggleTheme}
              className="mt-4 w-full flex items-center justify-center px-3 py-2 rounded-md text-base font-medium text-text dark:text-background hover:bg-primary hover:text-background"
            >
              {isDark ? <Sun size={20} className="mr-2" /> : <Moon size={20} className="mr-2" />}
              {isDark ? 'Light Mode' : 'Dark Mode'}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;