import axios from 'axios';
import authService from './AuthService';

const API_URL = 'http://localhost:5000/api/client'; // Adjust this URL to match your backend URL

class ClientService {
  constructor() {
    if (ClientService.instance) {
      return ClientService.instance;
    }
    ClientService.instance = this;
    this.init();
  }

  init() {
    // Ensure the auth header is set
    const token = authService.getToken();
    if (token) {
      authService.setAuthHeader(token);
    }
  }

  async getDashboard() {
    try {
      const response = await axios.get(`${API_URL}/dashboard`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch dashboard data');
    }
  }

  async getServices() {
    try {
      const response = await axios.get(`${API_URL}/services`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch services');
    }
  }

  async requestProject(projectData) {
    try {
      const response = await axios.post(`${API_URL}/request-project`, projectData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to submit project request');
    }
  }

  async getProjects() {
    try {
      const response = await axios.get(`${API_URL}/projects`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch projects');
    }
  }

  async getProject(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project/${projectId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project details');
    }
  }

  async getProposal(proposalId) {
    try {
      const response = await axios.get(`${API_URL}/proposal/${proposalId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch proposal');
    }
  }

  async respondToProposal(proposalId, decision) {
    try {
      const response = await axios.put(`${API_URL}/proposal/${proposalId}`, { decision });
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to respond to proposal');
    }
  }

  async getQuotation(quotationId) {
    try {
      const response = await axios.get(`${API_URL}/quotation/${quotationId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch quotation');
    }
  }

  async makePayment(projectId, paymentDetails) {
    try {
      const response = await axios.post(`${API_URL}/payment/${projectId}`, paymentDetails);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to process payment');
    }
  }
}

const clientService = new ClientService();
export default clientService;