import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext';
import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import AdminDashboard from './pages/AdminDashboard';
import ClientDashboard from './pages/ClientDashboard';
import Blog from './pages/Blog';
import NotFound from './pages/NotFound';
import UserManagement from './pages/UserManagement';
import authService from './services/AuthService';
import axios from 'axios';
import ServiceApplicationForm from './components/ServiceApplicationForm';
import CreateService from './components/CreateService';
import CreatePriceRange from './components/CreatePriceRange';

// In your Routes configuration


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const loggedIn = authService.isLoggedIn();
      if (loggedIn) {
        const user = authService.getCurrentUser();
        setUserRole(user.role);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
      }
      setIsLoading(false);
    };

    checkAuth();

    // Set up axios interceptor for token refresh
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await authService.refreshToken();
            return axios(originalRequest);
          } catch (refreshError) {
            // If refresh token fails, logout the user
            handleSignOut();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    // Clean up function
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const ProtectedRoute = ({ children, allowedRoles }) => {
    if (isLoading) return <div>Loading...</div>;
    if (!isAuthenticated) return <Navigate to="/SignIn" replace />;
    if (allowedRoles && !allowedRoles.includes(userRole)) {
      return <Navigate to="/unauthorized" replace />;
    }
    return children;
  };

  const UnauthenticatedRoute = ({ children }) => {
    if (isLoading) return <div>Loading...</div>;
    if (isAuthenticated) {
      return <Navigate to={userRole === 'client' ? '/client/dashboard' : '/admin/dashboard'} replace />;
    }
    return children;
  };

  const handleSignIn = (user) => {
    setIsAuthenticated(true);
    setUserRole(user.role);
  };

  const handleSignOut = async () => {
    try {
      await authService.signout();
    } catch (error) {
      console.error('Sign out failed:', error);
    } finally {
      setIsAuthenticated(false);
      setUserRole(null);
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
          <Navigation isAuthenticated={isAuthenticated} userRole={userRole} onSignOut={handleSignOut} />
          <div className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/blog" element={<Blog />} />
              <Route
                path="/SignUp"
                element={
                  <UnauthenticatedRoute>
                    <SignUp onSignUp={handleSignIn} />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/SignIn"
                element={
                  <UnauthenticatedRoute>
                    <SignIn onSignIn={handleSignIn} />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/client/dashboard"
                element={
                  <ProtectedRoute allowedRoles={['client']}>
                    <ClientDashboard />
                  </ProtectedRoute>
                }
              />
              <Route 
                path="/apply-for-service" 
                element={
                <ProtectedRoute allowedRoles={['client']}>
                  <ServiceApplicationForm />
                </ProtectedRoute>
                } 
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute allowedRoles={['admin', 'staff']}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route 
                path="/services/create" 
                element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <CreateService />
                </ProtectedRoute>
                } 
              />
              <Route 
                path="/price-ranges/create"
                element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <CreatePriceRange />
                </ProtectedRoute>
                } 
              />
              <Route
                path="/admin/users"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <UserManagement />
                  </ProtectedRoute>
                }
              />
              <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;