import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Edit, Trash2, RefreshCw, AlertCircle } from 'lucide-react';
import authService from '../services/AuthService'; // Adjust the import path as needed

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    setError('');
    try {
      const fetchedUsers = await authService.getAllUsers();
      setUsers(fetchedUsers);
    } catch (err) {
      setError(err.message || 'Failed to fetch users. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteUser = async () => {
    setIsLoading(true);
    setError('');
    try {
      await authService.deleteUser(userToDelete.id);
      setUsers(users.filter(user => user.id !== userToDelete.id));
      setIsDeleteModalOpen(false);
    } catch (err) {
      setError(err.message || 'Failed to delete user. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const saveUser = async (updatedUser) => {
    setIsLoading(true);
    setError('');
    try {
      const savedUser = await authService.updateUser(updatedUser);
      setUsers(users.map(user => user.id === savedUser.id ? savedUser : user));
      setIsEditModalOpen(false);
    } catch (err) {
      setError(err.message || 'Failed to update user. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-text dark:text-background">User Management</h1>
        <button
          onClick={fetchUsers}
          className="flex items-center px-4 py-2 bg-primary text-background rounded hover:bg-primary-hover transition-colors"
          disabled={isLoading}
        >
          <RefreshCw size={18} className={`mr-2 ${isLoading ? 'animate-spin' : ''}`} />
          Refresh
        </button>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
          <AlertCircle size={18} className="mr-2" />
          {error}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-background dark:bg-accent shadow-md rounded-lg">
          <thead className="bg-gray-200 dark:bg-gray-700">
            <tr>
              <th className="px-4 py-3 text-left text-text dark:text-background">Username</th>
              <th className="px-4 py-3 text-left text-text dark:text-background">Email</th>
              <th className="px-4 py-3 text-left text-text dark:text-background">Role</th>
              <th className="px-4 py-3 text-left text-text dark:text-background">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="border-b border-gray-200 dark:border-gray-600">
                <td className="px-4 py-3 text-text dark:text-background">{user.username}</td>
                <td className="px-4 py-3 text-text dark:text-background">{user.email}</td>
                <td className="px-4 py-3 text-text dark:text-background">{user.role}</td>
                <td className="px-4 py-3">
                  <button
                    onClick={() => handleEditUser(user)}
                    className="text-primary hover:text-primary-hover mr-2"
                    title="Edit user"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user)}
                    className="text-red-500 hover:text-red-700"
                    title="Delete user"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditModalOpen && (
        <EditUserModal
          user={editingUser}
          onSave={saveUser}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          user={userToDelete}
          onConfirm={confirmDeleteUser}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </motion.div>
  );
};

const EditUserModal = ({ user, onSave, onClose }) => {
  const [editedUser, setEditedUser] = useState(user);

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedUser);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-background dark:bg-accent p-6 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-text dark:text-background">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 text-text dark:text-background" htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={editedUser.username}
              onChange={handleChange}
              className="w-full p-2 border rounded text-text dark:text-background bg-background dark:bg-accent"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 text-text dark:text-background" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={editedUser.email}
              onChange={handleChange}
              className="w-full p-2 border rounded text-text dark:text-background bg-background dark:bg-accent"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 text-text dark:text-background" htmlFor="role">Role</label>
            <select
              id="role"
              name="role"
              value={editedUser.role}
              onChange={handleChange}
              className="w-full p-2 border rounded text-text dark:text-background bg-background dark:bg-accent"
            >
              <option value="client">Client</option>
              <option value="staff">Staff</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-background bg-gray-500 rounded mr-2 hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-background bg-primary rounded hover:bg-primary-hover transition-colors"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({ user, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-background dark:bg-accent p-6 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-text dark:text-background">Confirm Deletion</h2>
        <p className="mb-4 text-text dark:text-background">Are you sure you want to delete the user {user.username}?</p>
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-background bg-gray-500 rounded mr-2 hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-background bg-red-500 rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;