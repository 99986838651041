import React from 'react';
import { Link } from 'react-router-dom';

const ClientDashboard = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Client Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DashboardCard title="My Profile" link="/client/profile">
          View and edit your profile information
        </DashboardCard>
        <DashboardCard title="My Projects" link="/client/projects">
          Manage your ongoing and completed projects
        </DashboardCard>
        <DashboardCard title="Messages" link="/client/messages">
          Communicate with project managers and support
        </DashboardCard>
        <DashboardCard title="Billing" link="/client/billing">
          View invoices and manage payment methods
        </DashboardCard>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, children, link }) => {
  return (
    <Link to={link} className="block p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 dark:text-gray-300">{children}</p>
    </Link>
  );
};

export default ClientDashboard;