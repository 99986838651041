import axios from 'axios';

const API_URL = 'http://localhost:5000/api/auth'; // Adjust this URL to match your backend URL

class AuthService {
  constructor() {
    if (AuthService.instance) {
      return AuthService.instance;
    }
    AuthService.instance = this;
    this.init();
  }

  init() {
    this.setupAxiosInterceptors();
    const token = this.getToken();
    if (token) {
      this.setAuthHeader(token);
    }
  }

  setAuthHeader(token) {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }

  async getAllUsers() {
    try {
      const response = await axios.get(`${API_URL}/users`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch users');
    }
  }

  async updateUser(user) {
    try {
      const response = await axios.put(`${API_URL}/users/${user.id}`, user);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to update user');
    }
  }

  async deleteUser(userId) {
    try {
      await axios.delete(`${API_URL}/users/${userId}`);
    } catch (error) {
      throw error.response?.data || new Error('Failed to delete user');
    }
  }

  async login(username, password) {
    try {
      const response = await axios.post(`${API_URL}/login`, { username, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.setAuthHeader(response.data.token);
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Login failed');
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.setAuthHeader(null);
  }

  async signout() {
    try {
      await axios.post(`${API_URL}/logout`);
    } catch (error) {
      console.error('Signout error:', error);
    } finally {
      this.logout();
    }
  }

  async register(username, email, password) {
    try {
      const response = await axios.post(`${API_URL}/register`, {
        username,
        email,
        password
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Registration failed');
    }
  }

  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    if (!userStr) return null;
    try {
      return JSON.parse(userStr);
    } catch (error) {
      console.error('Error parsing user data:', error);
      this.logout();
      return null;
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isLoggedIn() {
    return !!this.getToken();
  }

  isAdmin() {
    const user = this.getCurrentUser();
    return user?.role === 'admin';
  }

  async refreshToken() {
    try {
      const response = await axios.post(`${API_URL}/refresh-token`);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        this.setAuthHeader(response.data.token);
      }
      return response.data;
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  setupAxiosInterceptors() {
    axios.interceptors.request.use(
      (config) => {
        const token = this.getToken();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await this.refreshToken();
            return axios(originalRequest);
          } catch (refreshError) {
            this.logout();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }
}

const authService = new AuthService();
export default authService;