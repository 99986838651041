import React from 'react';
import { motion } from 'framer-motion';
import { FaJs, FaReact, FaNodeJs, FaPython, FaAws, FaDocker, FaPhp } from 'react-icons/fa';
import { SiTypescript, SiGraphql, SiTailwindcss, SiQwik } from 'react-icons/si';
import { Layers } from 'lucide-react';

const SkillSlider = () => {
  const skills = [
    { name: 'JavaScript', icon: FaJs },
    { name: 'TypeScript', icon: SiTypescript },
    { name: 'React', icon: FaReact },
    { name: 'Node.js', icon: FaNodeJs },
    { name: 'Python', icon: FaPython },
    { name: 'AWS', icon: FaAws },
    { name: 'Docker', icon: FaDocker },
    { name: 'GraphQL', icon: SiGraphql },
    { name: 'PHP', icon: FaPhp },
    { name: 'Tailwind CSS', icon: SiTailwindcss },
    { name: 'Qwik', icon: SiQwik },
    { name: 'shadcn', icon: Layers },
  ];

  return (
    <div className="overflow-hidden py-8 shadow-md rounded-lg">
      <motion.div
        className="flex"
        animate={{ x: ['0%', '-50%'] }}
        transition={{ repeat: Infinity, duration: 20, ease: 'linear' }}
      >
        {[...skills, ...skills].map((skill, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center mx-8"
          >
            <skill.icon className="text-4xl mb-2 text-primary" />
            <span className="text-sm font-medium">{skill.name}</span>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default SkillSlider;