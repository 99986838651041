import React from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Admin/Staff Dashboard</h1>
      
      {/* Quick Action Buttons */}
      <div className="flex flex-wrap gap-4 mb-8">
        <Link to="/services/create" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Create New Service
        </Link>
        <Link to="/price-ranges/create" className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
          Create New Price Range
        </Link>
      </div>

      {/* Dashboard Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DashboardCard title="User Management" link="/admin/users">
          Manage user accounts and permissions
        </DashboardCard>
        <DashboardCard title="Service Management" link="/admin/services">
          Manage available services and specifications
        </DashboardCard>
        <DashboardCard title="Price Range Management" link="/admin/price-ranges">
          Manage price ranges for projects
        </DashboardCard>
        <DashboardCard title="Project Requests" link="/admin/project-requests">
          View and respond to new project requests
        </DashboardCard>
        <DashboardCard title="Active Projects" link="/admin/projects">
          Manage ongoing projects and timelines
        </DashboardCard>
        <DashboardCard title="Content Management" link="/admin/content">
          Manage blog posts and site content
        </DashboardCard>
        <DashboardCard title="Analytics" link="/admin/analytics">
          View site traffic and user engagement
        </DashboardCard>
        <DashboardCard title="Quotations" link="/admin/quotations">
          Manage project quotations
        </DashboardCard>
        <DashboardCard title="Settings" link="/admin/settings">
          Configure site-wide settings
        </DashboardCard>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, children, link }) => {
  return (
    <Link to={link} className="block p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 dark:text-gray-300">{children}</p>
    </Link>
  );
};

export default AdminDashboard;