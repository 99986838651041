import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

const ProjectCard = ({ project }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-background dark:bg-accent p-6 mx-6 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl"
    >
      <div className="aspect-w-16 aspect-h-9 mb-4">
        <img 
          src={project.image} 
          alt={project.name} 
          className="object-cover rounded-lg w-full h-40"
        />
      </div>
      <h3 className="text-xl font-semibold mb-2 text-text dark:text-background">{project.name}</h3>
      <p className="text-sm mb-4 text-text dark:text-background">{project.description}</p>
      <motion.a
        href={project.link}
        className="inline-flex items-center text-primary hover:text-primary-hover transition-colors duration-300"
        whileHover={{ x: 5 }}
      >
        Learn More <ArrowRight className="ml-2" size={20} />
      </motion.a>
    </motion.div>
  );
};

export default ProjectCard;