import React from 'react';
import { motion } from 'framer-motion';
import { User, Code, Server, Zap } from 'lucide-react';

const AboutMe = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="mb-24 bg-background dark:bg-accent rounded-3xl p-8 md:p-12"
    >
      <h2 className="text-3xl font-bold mb-8 flex items-center text-text dark:text-background">
        <User className="mr-4 text-primary" /> About Me
      </h2>
      <div className="space-y-6 text-text dark:text-background">
        <p className="leading-relaxed">
          Hi there! I'm George Odhiambo, a passionate Software Developer with a diverse skill set spanning both frontend and backend technologies. Over the years, I've gained extensive hands-on experience working with modern frameworks and libraries.
        </p>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1 bg-primary bg-opacity-10 dark:bg-opacity-20 p-6 rounded-2xl">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <Code className="mr-2 text-primary" /> Frontend Expertise
            </h3>
            <p>
              I specialize in building dynamic and interactive user interfaces using React and React Native. I'm also well-versed in UI frameworks like Tailwind CSS, Material-Tailwind, Daisy UI, and ShadcnUI for crafting visually appealing and responsive designs.
            </p>
          </div>
          <div className="flex-1 bg-primary bg-opacity-10 dark:bg-opacity-20 p-6 rounded-2xl">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <Server className="mr-2 text-primary" /> Backend Proficiency
            </h3>
            <p>
              On the backend, I'm proficient in Golang, Express.js, and Node.js. I enjoy leveraging these technologies to build robust and scalable server-side applications, creating RESTful APIs, handling data persistence, and implementing complex business logic.
            </p>
          </div>
        </div>
        <div className="bg-primary bg-opacity-10 dark:bg-opacity-20 p-6 rounded-2xl">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Zap className="mr-2 text-primary" /> Full-Stack Integration
          </h3>
          <p>
            What sets me apart is my ability to bridge the gap between frontend and backend development. I have a deep understanding of how these components interact and work together to create seamless end-to-end user experiences.
          </p>
        </div>
        <p className="leading-relaxed">
          I'm excited about the endless possibilities in the world of software development and always eager to take on new challenges. If you have any projects or ideas that could benefit from my skills and expertise, I'd love to hear more! Feel free to reach out, and let's discuss how we can collaborate to bring your vision to life.
        </p>
      </div>
    </motion.section>
  );
};

export default AboutMe;