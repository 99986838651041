import axios from 'axios';
import authService from './AuthService';

const API_URL = 'http://localhost:5000/api/admin'; // Adjust this URL to match your backend URL

class AdminService {
  constructor() {
    if (AdminService.instance) {
      return AdminService.instance;
    }
    AdminService.instance = this;
    this.init();
  }

  init() {
    // Ensure the auth header is set
    const token = authService.getToken();
    if (token) {
      authService.setAuthHeader(token);
    }
  }

  async getDashboard() {
    try {
      const response = await axios.get(`${API_URL}/dashboard`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch dashboard data');
    }
  }

  async getProjectRequests() {
    try {
      const response = await axios.get(`${API_URL}/project-requests`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project requests');
    }
  }

  async getProjectRequest(requestId) {
    try {
      const response = await axios.get(`${API_URL}/project-request/${requestId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project request details');
    }
  }

  async createProposal(requestId, proposalData) {
    try {
      const response = await axios.post(`${API_URL}/create-proposal/${requestId}`, proposalData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to create proposal');
    }
  }

  async getProjects() {
    try {
      const response = await axios.get(`${API_URL}/projects`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch projects');
    }
  }

  async getProject(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project/${projectId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project details');
    }
  }

  async updateProject(projectId, projectData) {
    try {
      const response = await axios.put(`${API_URL}/project/${projectId}`, projectData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to update project');
    }
  }

  async createQuotation(proposalId, quotationData) {
    try {
      const response = await axios.post(`${API_URL}/create-quotation/${proposalId}`, quotationData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to create quotation');
    }
  }

  async getProjectTimeline(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project-timeline/${projectId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project timeline');
    }
  }

  async createTimelineEntry(projectId, entryData) {
    try {
      const response = await axios.post(`${API_URL}/project-timeline/${projectId}`, entryData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to create timeline entry');
    }
  }

  async updateTimelineEntry(projectId, entryId, entryData) {
    try {
      const response = await axios.put(`${API_URL}/project-timeline/${projectId}/${entryId}`, entryData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to update timeline entry');
    }
  }

  // User management methods
  async getUsers() {
    try {
      const response = await axios.get(`${API_URL}/users`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch users');
    }
  }

  // Service management methods
  async getServices() {
    try {
      const response = await axios.get(`${API_URL}/services`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch services');
    }
  }

  async createService(serviceData) {
    try {
      const response = await axios.post(`${API_URL}/services`, serviceData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to create service');
    }
  }

  // Price range management methods
  async getPriceRanges() {
    try {
      const response = await axios.get(`${API_URL}/price-ranges`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch price ranges');
    }
  }

  async createPriceRange(priceRangeData) {
    try {
      const response = await axios.post(`${API_URL}/price-ranges`, priceRangeData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to create price range');
    }
  }
}

const adminService = new AdminService();
export default adminService;