import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../services/AdminService';

const CreatePriceRange = () => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    if (parseFloat(minPrice) >= parseFloat(maxPrice)) {
      setError('Minimum price must be less than maximum price');
      setIsLoading(false);
      return;
    }

    try {
      await adminService.createPriceRange({ min_price: minPrice, max_price: maxPrice });
      navigate('/admin/price-ranges', { state: { message: 'Price range created successfully!' } });
    } catch (err) {
      setError(err.message || 'Failed to create price range. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-5">Create New Price Range</h2>
      {error && <p className="text-red-500 mb-5">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="minPrice" className="block mb-1">Minimum Price:</label>
          <input
            type="number"
            id="minPrice"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            required
            min="0"
            step="0.01"
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="maxPrice" className="block mb-1">Maximum Price:</label>
          <input
            type="number"
            id="maxPrice"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            required
            min="0"
            step="0.01"
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
        >
          {isLoading ? 'Creating...' : 'Create Price Range'}
        </button>
      </form>
    </div>
  );
};

export default CreatePriceRange;