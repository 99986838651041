import axios from 'axios';
import authService from './AuthService';

const API_URL = 'http://localhost:5000/api'; // Adjust this URL to match your backend URL

class SharedService {
  constructor() {
    if (SharedService.instance) {
      return SharedService.instance;
    }
    SharedService.instance = this;
    this.init();
  }

  init() {
    // Ensure the auth header is set
    const token = authService.getToken();
    if (token) {
      authService.setAuthHeader(token);
    }
  }

  // Public routes (no authentication required)

  async getServices() {
    try {
      const response = await axios.get(`${API_URL}/services`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch services');
    }
  }

  async getServiceDetails(serviceId) {
    try {
      const response = await axios.get(`${API_URL}/service/${serviceId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch service details');
    }
  }

  // Protected routes (authentication required)

  async getProjectTimeline(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project/${projectId}/timeline`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project timeline');
    }
  }

  async getProjectPayments(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project/${projectId}/payments`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project payments');
    }
  }

  // Add more shared methods as needed

  // For example, if you have a shared endpoint for project details:
  async getProjectDetails(projectId) {
    try {
      const response = await axios.get(`${API_URL}/project/${projectId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch project details');
    }
  }

  // If you have a shared endpoint for user profile:
  async getUserProfile() {
    try {
      const response = await axios.get(`${API_URL}/user/profile`);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to fetch user profile');
    }
  }

  // If you have a shared endpoint for updating user profile:
  async updateUserProfile(profileData) {
    try {
      const response = await axios.put(`${API_URL}/user/profile`, profileData);
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Failed to update user profile');
    }
  }
}

const sharedService = new SharedService();
export default sharedService;