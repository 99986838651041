import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clientService from '../services/ClientService';
import sharedService from '../services/SharedService';

const ServiceApplicationForm = () => {
  const [services, setServices] = useState([]);
  const [priceRanges, setPriceRanges] = useState([]);
  const [formData, setFormData] = useState({
    serviceId: '',
    description: '',
    priceRangeId: '',
    specifications: {}
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [serviceSpecs, setServiceSpecs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
    fetchPriceRanges();
  }, []);

  const fetchServices = async () => {
    try {
      const fetchedServices = await sharedService.getServices();
      setServices(fetchedServices);
    } catch (err) {
      setError('Failed to fetch services. Please try again.');
    }
  };

  const fetchPriceRanges = async () => {
    try {
      const fetchedPriceRanges = await clientService.getPriceRanges();
      setPriceRanges(fetchedPriceRanges);
    } catch (err) {
      setError('Failed to fetch price ranges. Please try again.');
    }
  };

  const fetchServiceSpecs = async (serviceId) => {
    try {
      const specs = await clientService.getServiceSpecifications(serviceId);
      setServiceSpecs(specs);
      // Initialize specifications in formData
      const initialSpecs = specs.reduce((acc, spec) => {
        acc[spec.id] = '';
        return acc;
      }, {});
      setFormData(prev => ({ ...prev, specifications: initialSpecs }));
    } catch (err) {
      setError('Failed to fetch service specifications. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'serviceId') {
      fetchServiceSpecs(value);
    }
  };

  const handleSpecChange = (e, specId) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      specifications: { ...prev.specifications, [specId]: value }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await clientService.requestProject(formData);
      navigate('/client/dashboard', { state: { message: 'Project request submitted successfully!' } });
    } catch (err) {
      setError('Failed to submit project request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Apply for a Service</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="serviceId" className="block mb-1">Service:</label>
          <select
            id="serviceId"
            name="serviceId"
            value={formData.serviceId}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded"
          >
            <option value="">Select a service</option>
            {services.map(service => (
              <option key={service.id} value={service.id}>{service.name}</option>
            ))}
          </select>
        </div>

        {serviceSpecs.map(spec => (
          <div key={spec.id}>
            <label htmlFor={`spec-${spec.id}`} className="block mb-1">{spec.name}:</label>
            <input
              type="text"
              id={`spec-${spec.id}`}
              value={formData.specifications[spec.id] || ''}
              onChange={(e) => handleSpecChange(e, spec.id)}
              required
              className="w-full p-2 border rounded"
              placeholder={spec.description}
            />
          </div>
        ))}

        <div>
          <label htmlFor="description" className="block mb-1">Project Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded"
            rows="4"
          ></textarea>
        </div>

        <div>
          <label htmlFor="priceRangeId" className="block mb-1">Budget Range:</label>
          <select
            id="priceRangeId"
            name="priceRangeId"
            value={formData.priceRangeId}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded"
          >
            <option value="">Select a budget range</option>
            {priceRanges.map(range => (
              <option key={range.id} value={range.id}>
                ${range.min_price} - ${range.max_price}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors"
        >
          {loading ? 'Submitting...' : 'Submit Application'}
        </button>
      </form>
    </div>
  );
};

export default ServiceApplicationForm;